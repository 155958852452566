<template>
  <div>
    <CRow>
      <CCol sm="12">
        <CCard>
          <CCardHeader>
            <strong>IKLAN </strong><small>Edit Data</small>
          </CCardHeader>
          <CCardBody>
            <CForm @submit.prevent="updateIklan">
              <CRow>
                <CCol sm="3">
                  <img :src='val1' class="img-thumbnail mb-4" alt="" />
                </CCol>
                <CCol sm="9">
                  <CRow>
                    <CCol sm="6">
                    <CInput
                      v-model="title"
                      label="Nama Iklan"
                      placeholder="Input Nama Iklan"
                    />
                    </CCol>

                    <CCol sm="6">
                    <div class='form-group'>
                      <label>Ukuran</label>
                      <select v-model="advert_size" id="" class="form-control bold input-lg" required="">
                          <option class="bold" value="">Pilih Ukuran</option>
                          <option class="bold" value="2">300X300</option>
                          <option class="bold" value="3">728X90</option>
                          <option class="bold" value="4">970X90</option>
                          <option class="bold" value="5">970X450</option>
                      </select>
                    </div>
                    </CCol>
                    <CCol sm="6">
                      <div class="form-group">
                        <label>File Foto (jpeg/png/gif)</label><br/>                  
                        <div class="custom-file">
                          <input type="file" class="custom-file-input" @change="onFileChange($event)" id="inputGroupFile"
                          :placeholder="placeholder" aria-describedby="inputGroupFileAddon">
                          <label class="custom-file-label" for="inputGroupFile">{{placeholder}}</label>
                        </div>
                      </div>  
                    </CCol>
                    <CCol sm="6">
                      <CInput
                      type="text"
                      v-model="link"
                      label="Link"
                      placeholder="Input Urutan Iklan"
                    />
                    </CCol>  
                  </CRow>
                </CCol>
              </CRow>       
                <div class="form-actions">
                  <CButton type="submit" color="primary" style='margin-right:15px'>Simpan</CButton>
                  <CButton color="secondary" to="/iklan">Batal</CButton>
                </div>
            </CForm>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <CModal
      title="Error Input"
      color="danger"
      id="modalError"
    >
      <b>Error</b>! Data Iklan gagal diinput.
    </CModal>
   
  </div> 
  
</template>

<script>
import axios from 'axios';
// import * as Modals from '../notifications/Modals.vue'
// import { CurrencyInput } from 'vue-currency-input'

export default {
  // components: {CurrencyInput },
  name: 'Forms',
  computed : {
    years () {
      const year = new Date().getFullYear()
      return Array.from({length: year - 2019}, (value, index) => new Date().getFullYear() - index)
    }
  },
  data () {
    return {
      name : "",
      position : "",
      status: "",
      title : "",
      advert_size : "",
      val1 : "",
      val1_text : "",
      placeholder : "",
      link : "",
      // fproduct_price : "",
      // qty : "",
      // barcode : "",
      // alert : "",
      // unit : "",
      // product_des : "",
      selected: [], // Must be an array reference!
      show: true,
      horizontal: { label:'col-3', input:'col-9' },
      options: ['Option 1', 'Option 2', 'Option 3'],
      jenis: ['Penyelenggaran Jalan Kabupaten/Kota'],
      subjenis: ['Pembangunan dan Peningkatan Jalan', 'Rekonstruksi / Pemeliharaan / Rehabilitasi Jalan', 'Pembangunan Jembatan / Box Culvert'],
      tahuns: ['2021','2020'],
      selectOptions: [
        'Option 1', 'Option 2', 'Option 3',
        { 
          value: 'some value', 
          label: 'Selected option'
        }
      ],
      selectedOption: 'some value',

      formCollapsed: true,
      checkboxNames: ['Checkboxes', 'Inline Checkboxes',
                      'Checkboxes - custom', 'Inline Checkboxes - custom'],
      radioNames: ['Radios', 'Inline Radios',
                    'Radios - custom', 'Inline Radios - custom']
    }
  },
  mounted(){
  this.showIklan();
  },
  methods: {
    onFileChange(event) {
      var fileData = event.target.files[0];
      this.val1_text = fileData.name;
      this.val1 = fileData;
      this.placeholder = fileData.name;
    },
    // validator (val) {
    //   return val ? val.length >= 4 : false
    // },
    updateIklan: function(){
      // Simple POST request with a JSON body using axios
      const formData = new FormData();        
        
        formData.append('id', this.$route.params.id_iklan)
        formData.append('title', this.title)
        formData.append('advert_size', this.advert_size)
        formData.append('link', this.link)
        formData.append('status', this.status)
        if (this.val1_text) {
          if(this.val1.name){
            formData.append('val1', this.val1, this.val1.name) 
            formData.append('val1_text', this.val1.name) 
          }
        }
      // alert(JSON.stringify(pekerjaan));
      axios.post(process.env.VUE_APP_BASE_URL+"iklan/update", formData)
        .then((response) => {
            // console.log(response);
            // alert(response.data);
            if(response.status==200){
              this.$router.push('/iklan');
            }
            else{
              this.myError = true;
            }
            
        })
    },
    showIklan: function(){

      axios.get(process.env.VUE_APP_BASE_URL+"iklan/"+this.$route.params.id_iklan)
        .then((response) => {
              this.title=response.data.title;
              this.val1=response.data.val1;
              this.placeholder = response.data.val1;
              this.link=response.data.link;
              this.advert_size=response.data.advert_size;
              this.status=response.data.status;
              
            
        })
    }
  }
}
</script>